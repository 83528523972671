
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import {IArticleCategory, IArticleUpdate, articleTypes} from "@/models/article_model"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import EditorComponent from '@/components/editor/Editor.vue'
import {IArticlePlatform, IArticlePlatformCate} from "@/models/logistics_article_model";
import ApiLogistics from "@/request/apis/api_logistics";

interface IState {
  category: IArticlePlatformCate[]
  loading: boolean
  expandedKeys: number[]
}

// 新增编辑文章
export default defineComponent({
  name: "ArticleSet",
  components: {UploadComponent, EditorComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref();
    const selectedKeys = ref<string[]>([]);
    const formState: UnwrapRef<IArticlePlatform> = reactive({
      tag: "",
      description: "",
      cate_ids: []
    })
    const rules = {
      tag: [{required: true, message: "请输入唯一标识"},],
      description: [{required: true, message: "请输入描述"},],
      cate_ids: [{required: true, message: "请选择平台下属分类"},],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            if (editId) {
              params.id = editId
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const state: IState = reactive({
      category: [],
      loading: false,
      expandedKeys: []
    })

    // 获取分类列表
    const getCategoryList = async () => {
      const res = await ApiLogistics.articleCateTree({page_no:1, page_size:1000})
      if (res && res.error_code === 0) {
        state.category = exchangeListData(res.data || [])
      }
    }

    // 转换筛选list数据
    const exchangeListData = (list:IArticlePlatformCate[]) => {
      list.map(item => {
        item.children = item.children && item.children.length ? item.children : undefined;
        if (item.children && item.children.length) {
          item.children = exchangeListData(item.children);
        }
      })
      return list;
    }

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiLogistics.articlePlatDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.id = data.id;
          formState.description = data.description;
          formState.tag = data.tag
          formState.create_time = data.create_time;
          formState.update_time = data.update_time;
          formState.doc_cates = data.doc_cates;
          formState.cate_ids = data.doc_cates ? data.doc_cates.map((item:IArticlePlatformCate) => {
            return item.id;
          }) as number[] : [];
        }
      }
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiLogistics.updateOrAddArticlePlat(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiLogistics.updateOrAddArticlePlat(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 从html中获取图片集和
    const getImagesFromHtml = (htmlText: string) => {
      if (!htmlText) return ''
      const urls = Array.from(new DOMParser().parseFromString(htmlText, 'text/html')
          .querySelectorAll('img'))
          .map(img => img.getAttribute('src'));
      if (urls.length) {
        return urls.join(';')
      }
      return ''
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      getDetail()
      getCategoryList()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      articleTypes,
      ...toRefs(state),
      editorComponentRef,
      selectedKeys
    }
  },
})
